import {
  createContext,
  useState,
  useContext,
  useCallback,
  ReactNode,
  useMemo
} from 'react';
import { Auth } from 'aws-amplify';
import {
  KEY_ME,
  KEY_USER_PROFILE_ROLE,
  KEY_USER_PROFILE_USER
} from 'common/constants/localStorage.constant';
import { PERMISSION } from 'common/enums/permission.enum';
import useLocalStorage from 'common/hooks/useLocalStorage';
import { IRole } from 'common/interfaces/hierarchy.interface';
import { IUser } from 'common/interfaces/user.interface';
import { every, isArray } from 'lodash';
import { USER_TYPE } from 'common/enums/user.enum';
import { INSTRUCTOR_PERMISSIONS } from 'constants/instructors.constants';
interface AuthContextType {
  me: any;
  setMe: (value: any) => void;
  user: IUser | null;
  role: IRole | null;
  onChangeUser: (user: IUser | null) => void;
  onChangeRole: (role: IRole | null) => void;
  hasPermission: (permission: PERMISSION | Array<PERMISSION>) => boolean;
  refreshMe: () => void;
  login: () => void;
  logout: () => void;
  verifyAuth: () => void;
  totalNotification: number;
  onInitTotalNotifications: (total: number) => void;
  __isInstructorAccount: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return authContext;
};

interface Props {
  children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const [me, setMe] = useLocalStorage<any>(KEY_ME, null);

  const [user, setUser] = useLocalStorage<IUser | null>(
    KEY_USER_PROFILE_USER,
    null
  );
  const [role, setRole] = useLocalStorage<IRole | null>(
    KEY_USER_PROFILE_ROLE,
    null
  );
  const [totalNotification, setTotalNotification] = useState(0);

  const __isInstructorAccount = useMemo(() => {
    return user?.userType === USER_TYPE.INSTRUCTOR;
  }, [user]);

  const onInitTotalNotifications = useCallback((tt: number) => {
    setTotalNotification(tt);
    // eslint-disable-next-line
  }, []);

  const onChangeUser = useCallback((user: IUser | null) => {
    setUser(user);
    // eslint-disable-next-line
  }, []);

  const onChangeRole = useCallback((role: IRole | null) => {
    setRole(role);
    // eslint-disable-next-line
  }, []);

  const hasPermission = useCallback(
    (permission: PERMISSION | Array<PERMISSION>): boolean => {
      if (user?.userType === USER_TYPE.SUPER_ADMIN) return true;
      let roles = [...(role?.permissions || [])];
      if (user?.userType === USER_TYPE.INSTRUCTOR) {
        roles = [...INSTRUCTOR_PERMISSIONS];
      }
      if (!roles) return false;

      if (isArray(permission))
        return every(permission, (item) => hasPermission(item));
      return roles.includes(permission);
    },
    [role, user?.userType]
  );

  const verifyAuth = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();
    setMe(user?.signInUserSession?.idToken?.jwtToken || null);
  }, [setMe]);

  const refreshMe = useCallback(async () => {
    await verifyAuth();
  }, [verifyAuth]);

  const login = useCallback(async () => {
    await verifyAuth();
    window.location.reload();
  }, [verifyAuth]);

  const logout = useCallback(async () => {
    // Additional logout logic
    setMe(null);
    onChangeUser(null);
    onChangeRole(null);
    localStorage.clear();

    await Auth.signOut();
    window.location.reload();
  }, [setMe, onChangeUser, onChangeRole]);

  const authContextValue: AuthContextType = {
    me,
    setMe,
    user,
    role,
    onChangeUser,
    onChangeRole,
    hasPermission,
    refreshMe,
    login,
    logout,
    verifyAuth,
    totalNotification,
    onInitTotalNotifications,
    __isInstructorAccount
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
