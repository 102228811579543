import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Suspense,
  memo
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useLocation, useParams } from 'react-router-dom';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import { GoPlus } from 'react-icons/go';
import { HiMagnifyingGlass, HiOutlineIdentification } from 'react-icons/hi2';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import { useBrandLocation } from 'context/BrandLocationContext';
import { formatData } from 'common/helpers/dataFormat.helper';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import dayjs from 'dayjs';

import './desktop.scss';
import { getNotes } from 'services/note.service';
import { INoteDetail } from 'common/interfaces/note.interface';
import { IUser } from 'common/interfaces/user.interface';
import NoteAdd from '../NoteAdd';
import AppModal, {
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';

export interface RefHandle {
  refetchReset: () => void;
}

const NoteList = () => {
  const timezone = dayjs.tz.guess();
  const toast = useToast();
  const { id } = useParams();
  const location = useLocation();
  const columnHelper = createColumnHelper<INoteDetail>();

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotal, setPageTotal] = useState(-1);
  const [data, setData] = useState<INoteDetail[]>([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [note, setNote] = useState<INoteDetail | null>(null);
  const [loadData, setLoadData] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      if (!globalLocation?._id) return;
      const params: Record<string, unknown> = {
        limit: pageSize,
        page: pageIndex,
        keyword: debouncedSearch,
        intendedUserId: id
      };

      setLoadData(true);

      const { data, total } = await getNotes(params);
      setData(data);
      setPageTotal(total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch student list failed'
      );
    } finally {
      setLoadData(false);
    }

    // eslint-disable-next-line
  }, [
    globalLocation,
    pageSize,
    pageIndex,
    debouncedSearch,
    id,
    location.pathname
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRefresh = useCallback(() => {
    setPageIndex(1);
    setSearch('');
    fetchData();
  }, [fetchData]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('title', {
        header: () => <span>Title</span>,
        cell: (info) => {
          return (
            <div className="note-name-wrapper">
              {formatData(info.row.original?.title)}
            </div>
          );
        }
      }),
      columnHelper.accessor('content', {
        header: () => <span>Note</span>,
        cell: (info) =>
          formatData(
            info.row.original?.content?.slice(0, 50) +
              (info.row.original?.content?.length > 50 ? '...' : '')
          )
      }),
      columnHelper.accessor('creator', {
        header: () => <span>Created By</span>,
        cell: (info) => {
          const creator = info.row.original?.creator as IUser;
          return formatData(creator?.lastName + ', ' + creator?.firstName);
        }
      }),
      columnHelper.accessor('createdAt', {
        header: () => <span>Created At</span>,
        cell: (info) => {
          return dayjs(info.row.original?.createdAt)
            .tz(timezone)
            .format('DD-MM-YYYY HH:mm');
        }
      }),
      columnHelper.accessor('_id', {
        header: () => (
          <div style={{ cursor: 'pointer', textAlign: 'center' }}>Actions</div>
        ),
        cell: (info) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  title="View Note"
                  onClick={() => {
                    setViewNote(true);
                    setNote(info.row.original);
                  }}
                >
                  <HiOutlineIdentification fontSize={20} />
                </div>
              </PermissionWrapper>
            </div>
          );
        }
      })
    ];
  }, [columnHelper, timezone]);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageIndex(1);
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const getNoteModalContent = (note: INoteDetail) => {
    return (
      <div className="note-details">
        <p>
          <span className="note-details__label">Title:</span>
          {note?.title}
        </p>

        <p>
          <span className="note-details__label">Created By:</span>
          {note?.creator?.firstName} {note?.creator?.lastName}
        </p>
        <p>
          <span className="note-details__label">Created At:</span>
          {dayjs(note?.createdAt).tz().format('DD-MM-YYYY HH:mm')}
        </p>
        <br />
        <p>
          <span className="note-details__label">Note:</span>
          <br />
          {note?.content}
        </p>
      </div>
    );
  };

  const TablePure = () => {
    return (
      <>
        {/* <PermissionWrapper permission={PERMISSION.DELETE_STUDENT}> */}
        <Suspense>
          <NoteAdd
            openAdd={openAdd}
            onCloseAdd={function (): void {
              setOpenAdd(false);
            }}
            intendedUserId={id || ''}
            onSuccess={function (createdNote: any): void {
              handleRefresh();
            }}
          />
        </Suspense>

        {/* </PermissionWrapper> */}

        <Suspense>
          <AppModal
            open={viewNote}
            onClose={function (): void {
              setViewNote(false);
            }}
          >
            <AppModalFormTitle>Note Details</AppModalFormTitle>
            <AppModalFormContent>
              {getNoteModalContent(note as INoteDetail)}
            </AppModalFormContent>
          </AppModal>
        </Suspense>

        <div className="note">
          <AppCard>
            <div className="note__table">
              <div className="note__table-header">
                <h2 className="note__table-header-title">Notes</h2>
                <div className="note__table-header-search">
                  <AppInputSearch
                    inputSize="small"
                    type="text"
                    value={search}
                    onChange={onChangeSearch}
                    placeholder="Search Text..."
                    onClearSearch={onClearSearch}
                    startIcon={<HiMagnifyingGlass />}
                  />

                  <PermissionWrapper permission={PERMISSION.CREATE_STUDENT}>
                    <>
                      <AppButton
                        variant="primary"
                        buttonSize="small"
                        type="submit"
                        onClick={() => {
                          setOpenAdd(true);
                        }}
                      >
                        <div className="plusIcon">
                          <GoPlus style={{ width: '22px', height: '22px' }} />
                        </div>
                        <p>Note</p>
                      </AppButton>
                    </>
                  </PermissionWrapper>
                </div>
              </div>

              <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
                <div className="student__table-content">
                  <AppTable
                    loading={loadData}
                    data={data}
                    columns={columns}
                    pagination={{
                      index: pageIndex,
                      size: pageSize,
                      total: pageTotal
                    }}
                    onChangePage={(index: number, size: number) => {
                      setPageIndex(index);
                      setPageSize(size);
                    }}
                  />
                </div>
              </PermissionWrapper>
            </div>
          </AppCard>
        </div>
      </>
    );
  };

  return <>{TablePure()}</>;
};

export default memo(NoteList);
