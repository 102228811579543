import { UserbackProvider, useUserback } from '@userback/react';
import { useAuth } from './AuthContext';
import { useEffect } from 'react';

// not in use

interface ExtendedXMLHttpRequest extends XMLHttpRequest {
  _url?: string;
  _method?: string;
}

interface NetworkLog {
  url: string;
  status: number;
  method?: string;
  timestamp: number;
}

// Add this interface at the top of the file with other interfaces
declare global {
  interface Window {
    getNetworkLogs: () => NetworkLog[];
  }
}

function logNetworkStatus() {
  const networkLogs: NetworkLog[] = [];
  const originalFetch = window.fetch;
  window.fetch = async function (...args) {
    const [resource, init] = args;
    const timestamp = Date.now();

    try {
      const response = await originalFetch(...args);
      networkLogs.push({
        url: resource.toString(),
        method: init?.method || 'GET',
        status: response.status,
        timestamp
      });
      return response;
    } catch (err) {
      networkLogs.push({
        url: resource.toString(),
        method: init?.method || 'GET',
        status: 0,
        timestamp
      });
      throw err;
    }
  };
  // Intercept XHR
  const originalXHROpen = XMLHttpRequest.prototype.open;
  const originalXHRSend = XMLHttpRequest.prototype.send;
  // @ts-ignore
  XMLHttpRequest.prototype.open = function (
    this: ExtendedXMLHttpRequest,
    ...args: [method: string, url: string | URL, async: boolean]
  ) {
    const [method, url] = args;
    this._url = url as any;
    this._method = method;
    originalXHROpen.apply(this, args);
  };
  XMLHttpRequest.prototype.send = function (
    this: ExtendedXMLHttpRequest,
    ...args
  ) {
    const timestamp = Date.now();

    this.addEventListener('load', function (this: ExtendedXMLHttpRequest) {
      networkLogs.push({
        url: this._url || '',
        status: this.status,
        method: this._method,
        timestamp
      });
    });
    this.addEventListener('error', function (this: ExtendedXMLHttpRequest) {
      networkLogs.push({
        url: this._url || '',
        status: 0,
        method: this._method,
        timestamp
      });
    });
    originalXHRSend.apply(this, args);
  };
  // Expose logs getter
  return () => networkLogs;
}
// // Initialize the logger and expose it globally
(window as any).getNetworkLogs = logNetworkStatus();
// not in use close

export const MyUserBackComponent = () => {
  const { user } = useAuth();
  const Userback = useUserback();

  useEffect(() => {
    Userback.init(process.env.REACT_APP_USERBACK_API_KEY || '', {
      after_send: (data: any) => {
        console.clear();
      },
      before_send: () => {
        const originalWarn = console.warn;
        const originalError = console.error;

        const allLogs: any[] = [];

        console.warn = function (...args) {
          allLogs.push({ type: 'warn', data: args });
          originalWarn.apply(console, args);
        };

        console.error = function (...args) {
          allLogs.push({ type: 'error', data: args });
          originalError.apply(console, args);
        };

        Userback.setData({
          errorLogs: JSON.stringify(allLogs)
        });
      }
    });
    Userback.identify(user?.memberId.toString() || '', {
      name: user?.firstName + ' ' + user?.lastName,
      email: user?.email || ''
    });
  }, [Userback, user]);

  return <></>;
};

export const UserBackComponent = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <UserbackProvider token={process.env.REACT_APP_USERBACK_API_KEY || ''}>
      <MyUserBackComponent></MyUserBackComponent>
      {children}
    </UserbackProvider>
  );
};
