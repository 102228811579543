import { LayoutProvider } from 'context/LayoutContext';
import { Amplify } from 'aws-amplify';
import { awsmobile } from 'common/constants/aws.constant';
import { ToastProvider } from 'context/ToastContext';
import { BrandLocationProvider } from 'context/BrandLocationContext';
import { AuthProvider } from 'context/AuthContext';
import { AxiosWrapper } from 'services/axiosClient';
import { ThemeProvider } from 'context/ThemeContext';
import RouteList from 'routes';

import './App.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserBackComponent } from 'context/UserbackContext';

// Create a client
const queryClient = new QueryClient();

Amplify.configure(awsmobile);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ToastProvider>
          <AuthProvider>
            <UserBackComponent>
              <AxiosWrapper>
                <BrandLocationProvider>
                  <LayoutProvider>
                    <RouteList />
                  </LayoutProvider>
                </BrandLocationProvider>
              </AxiosWrapper>
            </UserBackComponent>
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
